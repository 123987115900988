import React from 'react';
import PropTypes from 'prop-types';
import {
  faExternalLink,
  faBadge,
  faCheck,
  faIndustry,
  faLightbulb,
  faServer,
  faTrophy,
} from '@fortawesome/pro-regular-svg-icons';
import Typography from '../../Typography';
import Grid from '../../Grid';
import CardBody from '../../CardBody';
import Card from '../../Card';
import CardHeader from '../../CardHeader';
import Icon from '../../Icon';
import Image from '../../Image';
import './style.scss';
import Button from '../../Button';

const getFeatureIcon = name => {
  switch (name.toLowerCase()) {
    case 'industrie':
      return faIndustry;
    case 'vorig platform':
      return faServer;
    case 'uitdaging':
      return faBadge;
    case 'oplossingen':
      return faLightbulb;
    case 'resultaat':
      return faTrophy;
    default:
      return faCheck;
  }
};

const CaseIntroBlock = ({ title, contents }) => {
  const [firstContent] = contents;
  const { body, images } = firstContent;
  const [image] = images;
  const features = contents.filter(content => content.title?.toLowerCase().includes('feature|'));
  const [cta] = contents.filter(content => content.title?.toLowerCase().includes('cta'));

  return (
    <div className="case-intro-block pb-40">
      <Grid container>
        <Grid row justify="between">
          <Grid column xs={12} md={6} lg={6} className="py-56">
            <Typography variant="h2" component="h1" className="mb-24 d-block">
              {title}
            </Typography>
            <Typography variant="body-large" color="gray-af-70" className="mb-24">
              {body}
            </Typography>
            <div className="case-logo">
              <Image src={image?.url} alt={image?.alternativeText} height={image?.height} width={image?.width} />
            </div>
          </Grid>
          <Grid column xs={12} md={6} lg={5}>
            <Card className="mt-md-n144">
              <CardHeader disableBorder>
                <Typography variant="subtitle-1" weight="medium">
                  Case study
                </Typography>
              </CardHeader>
              <CardBody>
                {features && (
                  <ul className="list-unstyled">
                    {features.map(feature => (
                      <li className="d-flex align-items-start py-12" key={feature.title}>
                        <Icon
                          icon={getFeatureIcon(feature?.title?.split('|')[1])}
                          className="text-primary mr-24 mt-8"
                          size="lg"
                        />
                        <div>
                          <Typography variant="body-large" color="gray-af-110" weight="medium" className="mb-4">
                            {feature?.title?.split('|')[1]}
                          </Typography>
                          <Typography variant="body">{feature?.body}</Typography>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {cta && (
                  <Button href={cta.cta_url_1} block target="_blank" rel="noreferrer">
                    {cta.cta_label_1} <Icon icon={faExternalLink} className="ml-8" />
                  </Button>
                )}
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

CaseIntroBlock.propTypes = {
  title: PropTypes.string,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

CaseIntroBlock.defaultProps = {
  title: undefined,
  contents: [],
};

export default CaseIntroBlock;
