import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Grid from '../../components/Grid';
import Layout from '../../components/Layout';
import Typography from '../../components/Typography';
import Divider from '../../components/Divider';
import HeadingBlock from '../../components/blocks/HeadingBlock';
import UspsBlock from '../../components/blocks/UspsBlock';
import TextAndImageBlock from '../../components/blocks/TextAndImageBlock';
import QuoteBlock from '../../components/blocks/QuoteBlock';
import CtaBlock from '../../components/blocks/CtaBlock';
import ListBlock from '../../components/blocks/ListBlock';
import getSchemaBreadcrumbs from '../../utils/getSchemaBreadcrumbs';
import Breadcrumbs from '../../components/Breadcrumbs';
import CaseHeaderBlock from '../../components/blocks/CaseHeader';
import CaseIntroBlock from '../../components/blocks/CaseIntro';
import LogosBlock from '../../components/blocks/LogosBlock';
import TextBlock from '../../components/blocks/TextBlock';
import formatLocale from '../../utils/formatLocale';

const BlocksPage = ({ data, location }) => {
  const { site, strapi } = data;
  const { page: strapiPage } = strapi;
  const { blocks = [], seo_title: seoTitle, seo_description: seoDescription, locale, localizations, slug } =
    strapiPage || {};
  const [lastBlock] = [...blocks].reverse();

  return (
    <Layout
      location={location}
      hideCta={lastBlock?.type === 'cta'}
      slug={slug}
      locale={locale}
      localizations={localizations}
    >
      <Helmet
        title={seoTitle}
        meta={[
          {
            name: 'description',
            content: seoDescription,
          },
        ]}
      >
        <html lang={formatLocale(locale)} />
        <script type="application/ld+json">
          {`
            [
              ${getSchemaBreadcrumbs(location)}
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "${site?.siteMetadata?.siteName}",
                "logo": "${site?.siteMetadata?.logo}",
                "@id": "${site?.siteMetadata?.company?.kvk}",
                "url": "${site?.siteMetadata?.siteUrl}",
                "foundingDate": "${site?.siteMetadata?.company?.foundingDate}",
                "founders": ["${site?.siteMetadata?.company?.founders.join('", "')}"],
                "contactPoint": {
                  "@context": "http://schema.org",
                  "@type": "contactPoint",
                  "contactType": "customer service",
                  "telephone": "${site?.siteMetadata?.company?.phoneNumber}",
                  "email": "${site?.siteMetadata?.company?.email}"
                },
                "sameAs": ["${site?.siteMetadata?.twitter}", "${site?.siteMetadata?.facebook}"],
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "${site?.siteMetadata?.address?.streetAddress}",
                  "addressLocality": "${site?.siteMetadata?.address?.locality}",
                  "addressRegion": "${site?.siteMetadata?.address?.region}",
                  "postalCode": "${site?.siteMetadata?.address?.postalCode}",
                  "addressCountry": "${site?.siteMetadata?.address?.country}"
                }
              }
            ]
          `}
        </script>
      </Helmet>
      <Breadcrumbs
        location={location}
        locale={locale}
        className="mt-24"
        wrapper={props => <Grid container {...props} />}
      />
      {(blocks || [])?.map(({ title, pre_title: preTitle, type, contents, id }) => {
        switch (type) {
          case 'header':
            return <HeadingBlock key={id} title={title} preTitle={preTitle} contents={contents} />;
          case 'case_header':
            return <CaseHeaderBlock key={id} title={title} preTitle={preTitle} contents={contents} />;
          case 'case_intro':
            return <CaseIntroBlock key={id} title={title} contents={contents} />;
          case 'usps_4':
            return <UspsBlock key={id} title={title} contents={contents} />;
          case 'text_left':
            return <TextAndImageBlock key={id} title={title} contents={contents} />;
          case 'text_right':
            return <TextAndImageBlock key={id} textRight title={title} contents={contents} />;
          case 'quote_1':
            return <QuoteBlock key={id} title={title} preTitle={preTitle} contents={contents} />;
          case 'cta':
            return <CtaBlock key={id} contents={contents} />;
          case 'list':
            return <ListBlock key={id} title={title} contents={contents} />;
          case 'logos':
            return <LogosBlock key={id} title={title} contents={contents} />;
          case 'text':
            return <TextBlock key={id} title={title} contents={contents} />;
          default:
            return (
              <Grid key={id} container className="py-40">
                <Grid column xs={12}>
                  <Typography>block: {title}</Typography>
                  <Typography>blockType: {type}</Typography>
                  <Divider />
                </Grid>
              </Grid>
            );
        }
      })}
    </Layout>
  );
};

BlocksPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

BlocksPage.defaultProps = {
  data: {
    markdownRemark: {
      frontmatter: {},
      timeToRead: 0,
    },
  },
  location: undefined,
};

export default BlocksPage;

export const pageQuery = graphql`
  query($id: ID!) {
    site {
      siteMetadata {
        logo
        siteUrl
        siteName
        twitter
        facebook
        company {
          foundingDate
          founders
          phoneNumber
          email
        }
        address {
          streetAddress
          locality
          region
          postalCode
          country
        }
      }
    }
    strapi {
      page(id: $id) {
        blocks {
          contents {
            body
            cta_label_1
            cta_url_1
            images {
              alternativeText
              height
              width
              url
            }
            title
          }
          id
          title
          pre_title
          type
        }
        seo_title
        seo_description
        title
        slug
        locale
        localizations {
          locale
          slug
        }
      }
    }
  }
`;
