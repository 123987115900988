import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../Grid';
import Typography from '../../Typography';
import Markdown from '../../Markdown';

const TextBlock = ({ contents }) => {
  const [firstContent] = contents;
  const { body } = firstContent;
  return (
    <Grid container>
      <Markdown
        components={{
          // eslint-disable-next-line react/prop-types
          paragraph: ({ children }) => <Typography className="mb-24">{children}</Typography>,
        }}
      >
        {body}
      </Markdown>
    </Grid>
  );
};

TextBlock.propTypes = {
  contents: PropTypes.array,
};

TextBlock.defaultProps = {
  contents: [],
};

export default TextBlock;
