import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../Typography';
import Grid from '../../Grid';
import './style.scss';
import dasherize from '../../../utils/dasherize';
import Markdown from '../../Markdown';

const UspsBlock = ({ contents }) => (
  <div className="usps-block pt-96 pb-88">
    <Grid container>
      <Grid row className="my-n24 my-md-n28 my-lg-0">
        {contents.map(usp => (
          <Grid key={dasherize(usp.title)} column xs={12} md={6} lg={4} className="py-24 py-md-28 py-lg-0">
            <Typography variant="h6" component="span" className="mb-12 d-block">
              {usp?.title}
            </Typography>
            <Typography variant="body-large">
              <Markdown>{usp?.body}</Markdown>
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  </div>
);

UspsBlock.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

UspsBlock.defaultProps = {
  contents: [],
};

export default UspsBlock;
