import React from 'react';
import PropTypes from 'prop-types';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import Typography from '../../Typography';
import Grid from '../../Grid';
import './style.scss';
import Icon from '../../Icon';

const ListBlock = ({ title, contents }) => (
  <div className="list-block py-96">
    <Grid container>
      <Typography variant="h4" component="div" className="mb-32 d-block">
        {title}
      </Typography>
      <Grid row className="my-n10">
        {contents.map(item => (
          <Grid column xs={12} md={6} xl={4} className="py-10" key={item?.title}>
            <div className="d-flex align-items-start">
              <Icon icon={faCheckCircle} className="text-primary mr-12" />
              <Typography>{item?.title}</Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  </div>
);

ListBlock.propTypes = {
  title: PropTypes.string,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

ListBlock.defaultProps = {
  title: undefined,
  contents: [],
};

export default ListBlock;
