import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Grid from '../../Grid';
import Image from '../../Image';
import isRelativeUri from '../../../utils/isRelativeUri';

const LogosBlock = ({ contents }) => (
  <Grid container component="section" className="py-56">
    <Grid row>
      {contents.map(content => {
        const { images, cta_url_1: ctaUrl } = content;
        const [logo] = images;

        if (ctaUrl) {
          const isRelative = isRelativeUri(ctaUrl);
          const Component = isRelative ? Link : 'a';
          return (
            <Grid column xs={6} sm={4} md={3} key={logo.url}>
              <Component {...(isRelative ? { to: ctaUrl } : { href: ctaUrl })}>
                <Image src={logo.url} alt={logo.alternativeText} height={logo.height} width={logo.width} />
              </Component>
            </Grid>
          );
        }

        return (
          <Grid column xs={6} sm={4} md={3} key={logo.url}>
            <Image src={logo.url} alt={logo.alternativeText} height={logo.height} width={logo.width} />
          </Grid>
        );
      })}
    </Grid>
  </Grid>
);

LogosBlock.propTypes = {
  contents: PropTypes.array,
};

LogosBlock.defaultProps = {
  contents: [],
};

export default LogosBlock;
