import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../Typography';
import Grid from '../../Grid';
import './style.scss';
import Image from '../../Image';

const CaseHeaderBlock = ({ title, preTitle, contents }) => {
  const [firstContent] = contents;
  const { body, images } = firstContent;
  return (
    <div className="case-header-block pt-76 pb-40 py-md-80">
      <Grid container>
        <Typography variant="overline" component="div" color="gray-af-90" align="center" className="mb-24 d-block">
          {preTitle}
        </Typography>
        <Typography variant="h2" component="h1" align="center" className="mb-24 d-block">
          {title}
        </Typography>
        <Typography variant="body" align="center" color="gray-af-70" className="mb-12">
          {body}
        </Typography>
      </Grid>
      <div className="d-flex mt-56">
        {images.map(image => (
          <div className="flex-fill">
            <Image src={image.url} alt={image.alternativeText} height={image.height} width={image.width} />
          </div>
        ))}
      </div>
    </div>
  );
};

CaseHeaderBlock.propTypes = {
  title: PropTypes.string,
  preTitle: PropTypes.string,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

CaseHeaderBlock.defaultProps = {
  title: undefined,
  preTitle: undefined,
  contents: [],
};

export default CaseHeaderBlock;
