import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../Typography';
import Grid from '../../Grid';
import Image from '../../Image';
import './style.scss';
import Markdown from '../../Markdown';
import Button from '../../Button';

const TextAndImageBlock = ({ title: blockTitle, preTitle, contents, textRight }) => {
  const [firstContent] = contents;
  const { body, images, title, cta_label_1: ctaLabel, cta_url_1: ctaUrl } = firstContent;
  const [image] = images ?? [];

  return (
    <div className="py-96 overflow-hidden text-and-image-block">
      <Grid container>
        <Grid row justify="between" direction={textRight ? 'row-reverse' : 'row'} align="center">
          <Grid column xs={12} md={6} lg={5} className="mb-48">
            {preTitle && (
              <Typography variant="h6" component="span" className="mb-40 d-block text-secondary">
                {preTitle}
              </Typography>
            )}
            {(title || blockTitle) && (
              <Typography variant="h4" component="h2" className="mb-24">
                {title ?? blockTitle}
              </Typography>
            )}
            {body && (
              <Markdown
                components={{
                  // eslint-disable-next-line react/prop-types
                  paragraph: ({ children }) => (
                    <Typography variant="body-large" className="mb-24">
                      {children}
                    </Typography>
                  ),
                }}
              >
                {body}
              </Markdown>
            )}
            {ctaLabel && ctaUrl && (
              <Button href={ctaUrl} className="mt-24">
                {ctaLabel}
              </Button>
            )}
          </Grid>
          {image && (
            <Grid column xs={12} md={5} lg={6}>
              <Image src={image?.url} width={image?.width} height={image?.height} alt={image?.alternativeText} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

TextAndImageBlock.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      cta: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
      images: PropTypes.arrayOf(
        PropTypes.shape({
          alternativeText: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
    }),
  ),
  title: PropTypes.string,
  preTitle: PropTypes.string,
  textRight: PropTypes.bool,
};

TextAndImageBlock.defaultProps = {
  contents: [],
  title: undefined,
  preTitle: undefined,
  textRight: false,
};

export default TextAndImageBlock;
