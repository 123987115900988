import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '../../Typography';
import Button from '../../Button';
import Grid from '../../Grid';
import Image from '../../Image';
import './style.scss';

const HeadingBlock = ({ title, preTitle, contents, color }) => {
  const [firstContent] = contents;
  const { cta_label_1: ctaLabel, cta_url_1: ctaUrl, body, images } = firstContent ?? {};
  const [image] = images ?? [];

  return (
    <div
      className={clsx('py-64 heading-block overflow-hidden', {
        'heading-block-dark': color === 'dark',
      })}
    >
      <Grid container>
        <Grid row justify="between" align="center">
          <Grid column xs={12} md={7} lg={5}>
            {preTitle && (
              <Typography
                variant="h6"
                component="span"
                weight="medium"
                className="mb-40 d-block heading-block-pre-title"
              >
                {preTitle}
              </Typography>
            )}
            <Typography
              variant="h1"
              color={color === 'dark' ? 'white' : 'gray-af-110'}
              className={clsx('heading-block-title', { 'mb-24': body })}
            >
              {title}
            </Typography>
            {body && (
              <Typography
                variant="body-large"
                color={color === 'dark' ? 'gray-af-60' : 'gray-af-100'}
                className="heading-block-text mb-24"
              >
                {body}
              </Typography>
            )}
            {ctaLabel && ctaUrl && <Button href={ctaUrl}>{ctaLabel}</Button>}
          </Grid>
          {image && (
            <Grid column xs={12} md={6} className="d-none d-md-block">
              <Image
                src={image?.url}
                width={image?.width}
                height={image?.height}
                objectFit="contain"
                alt={image?.alternativeText}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

HeadingBlock.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      cta: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
      images: PropTypes.arrayOf(
        PropTypes.shape({
          alternativeText: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
    }),
  ),
  color: PropTypes.oneOf(['light', 'dark']),
  title: PropTypes.string,
  preTitle: PropTypes.string,
};

HeadingBlock.defaultProps = {
  color: 'light',
  contents: [],
  title: undefined,
  preTitle: undefined,
};

export default HeadingBlock;
