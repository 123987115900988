import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../Typography';
import Grid from '../../Grid';
import './style.scss';

const QuoteBlock = ({ title, preTitle, contents }) => {
  const [quote] = contents;
  return (
    <div className="quote-block pt-48 pb-64">
      <Grid container>
        <Grid row justify="center" className="my-n24 my-md-n28 my-lg-0">
          <Grid column xs={12} md={8} lg={6} className="py-24 py-md-28 py-lg-0">
            <Typography variant="h1" component="span" color="gray-af-110" className="mb-12 d-block" align="center">
              “
            </Typography>
            <Typography variant="body-large" align="center" className="mb-48" color="gray-af-100">
              “{quote?.body}”
            </Typography>
            <Typography variant="h6" component="div" align="center" className="mb-24" color="gray-af-110">
              {title}
            </Typography>
            <Typography align="center" className="mb-24" color="gray-af-90">
              {preTitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

QuoteBlock.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
  preTitle: PropTypes.string,
};

QuoteBlock.defaultProps = {
  contents: [],
  title: undefined,
  preTitle: undefined,
};

export default QuoteBlock;
